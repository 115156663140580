body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    box-sizing: border-box;
}

.section1 {
    /* width: 1440px; */
    height: 130vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* background-image: url('../images/badmintonPlayerPage1.jpg');  */
    /* background-size: cover; */
    /* background-position:  center; */
    color: #fff;
    align-items: center;
    min-height: 100vh;
    flex-shrink: 0;
    /* opacity: 0.7; */
    /* background: linear-gradient(270deg, rgba(0, 12, 29, 0.80) 30.78%, rgba(0, 9, 23, 0.00) 99.8%); */
    background-color: white;
    position: relative;
    background-size: cover;

}

.section1::before {
    content: "";
    background-image: url('../images/badmintonPlayerPage1.jpg');
    background-size: cover;
    background-position: right;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.book-demo-btn {
    display: flex;
    padding: 16px 32px;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    background-color: #62ECBC;
    backdrop-filter: blur(2px);
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    border: none;
    cursor: pointer;
    color: var(--Primary-1, #081F40);

    margin-left: 80px;
}
.navigation {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    margin-right: 100px;
    align-items: center;
    z-index: 999;

}


.logo1 {
    margin-left: 100px;
    display: inline-flex;
    padding: 12px 16px;
    /* margin-top: 22px; */
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    gap: 10px;
    background: rgba(244, 244, 244, 0.50);
}



.nav-list {
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    /* Added gap */
    list-style: none;
}

.nav-list li {
    color: var(--Primary-1, #081F40);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 10px;
    background: grey;
    /* Background color */
    padding: 18px 20px;
    /* Added padding */
}

.section1 .heading {
    grid-column-start: 2;

    display: flex;

    flex-direction: column;
    justify-content: center;

    align-items: flex-end;
    /* align-items: center; */
    text-align: right;
    /*text-align: center;*/
    padding-right: 100px;
}

.content {
    padding: 0 5px;
    margin-right: 80px;
}




.main-heading {
    display: block;
    margin-bottom: 10px;
    color: #FFF;
    font-family: Poppins;
    font-size: 88px;
    font-style: normal;
    text-align: justify;
    margin-left: 80px;
    font-weight: 500;
    line-height: 92px;

    text-transform: uppercase;
}

.desc-heading {
    display: block;
    text-align: justify;
    font-family: Poppins;

    font-size: 16px;
    font-weight: 100;
    margin-bottom: 20px;
    margin-left: 80px;
    line-height: 1.6;

    gap: 40px;
}

/* Section-2 Styles */
.section2 {
    /* /* width: 1440px; */
    height: 130vh;
    flex-shrink: 0;
    display: flex;
    background-image: url('../images/footballPage1.jpg');
    /* background:url('../images/full-shot-couch-training-kids\ 1.png'), lightgray -0.134px 0px / 120.852% 101.995% no-repeat; */
    background-size: cover;
    min-height: 100vh;
}



.section2 .content {
    margin-bottom: 200px;

    padding: 0 50px;
    align-items: center;
    justify-content: flex-start;


}


.section2 .main-heading {
    /* display: inline-flex; */
    /* margin-bottom: 10px; */
    color: var(--Grey-40, rgba(0, 0, 0, 0.40));
    /* Updated color */
    font-family: Poppins;
    font-size: 84px;
    /* Updated font size */
    font-style: normal;
    text-align: justify;
    /* font-weight: 275;  */
    line-height: normal;
    text-transform: uppercase;
    /* margin-top: 60px; */
    margin-top: 250px;
    margin-left: 150px;

    white-space: nowrap;


}

.section2 .desc-heading {
    color: #081F40;
    word-wrap: break-word;

    text-align: justify;
    font-weight: 100;
    line-height: normal;
    text-transform: uppercase;
    margin-left: 160px;

    font-size: 48px;
    font-family: Poppins;
}


/* Section-3 Styles */
.section3 {

    /* display: grid; */
    /* width: 1440px;
    height: 960px; */
    flex-shrink: 0;

    grid-template-columns: repeat(2, 1fr);
    background-image: url('../images/swimmingPlayerPage1.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    align-items: center;
    min-height: 130vh;
    /* opacity: 0.7; */
    background: linear-gradient(270deg, rgba(20, 36, 54, 0.8) 10.78%, rgba(0, 9, 23, 0.00) 99.8%);
    position: relative;
}
.section3::before {
    content: "";
    background-image: url('../images/swimmingPlayerPage1.jpg');
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.section3 .content3 {
    /* margin-left: 80px; */
    display: flex;
    justify-content: flex-end;

}

.section3 .heading3 {
    display: flex;
    flex-direction: column;
    width: 50%;

    float: right;
    justify-content: flex-end;
    align-content: center;
    /* margin: 75px 25px; */
    /* align-content: center; */
    margin-top: 130px;
}


.icon-custom1,
.icon-custom2 {
    width: 48px;
    height: 48px;
}



.section3 .main-heading3 {

    text-align: center;
    align-self: flex-end;
    margin-right: 130px;
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.80);
    font-size: 64px;
    font-family: Inter;
    gap: 28px;
    font-weight: 400;
    text-transform: uppercase;
    word-wrap: break-word;
}

.customize-section3 {
    display: flex;
    align-self: flex-end;
    margin-right: 160px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 24px;
    border-radius: 16px;

}

.seamless-section3 {

    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    padding: 40px 24px;
    margin-right: 160px;
    border-radius: 16px;

}

.customize3 {
    color: rgba(255, 255, 255, 0.90);
    font-size: 20px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;

}

.membership3 {
    flex: 1 1 0;
    color: white;
    font-size: 14px;
    font-family: Inter;
    font-weight: 200;
    word-wrap: break-word;
    text-align: center;
    line-height: normal;
    width: 100%;
}

.seemless3 {
    color: rgba(255, 255, 255, 0.90);
    font-style: normal;
    font-size: 20px;
    line-height: normal;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
}

.effortless3 {
    color: white;
    text-align: center;
    font-size: 14px;
    line-height: normal;
    font-family: Inter;
    font-weight: 200;
    word-wrap: break-word;
    width: 100%;

}

/* section 4 */

.section4 {

    /* width: 1440px;
    height: 960px;  */
    flex-shrink: 0;

    /* grid-template-columns: repeat(2, 1fr); */
    background-image: url('../images/skaterPage1.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    align-items: center;
    min-height: 130vh;
    /* opacity: 0.79; */
    background: linear-gradient(90deg, rgba(41, 41, 41, 0.6) 30.78%, rgba(0, 9, 23, 0.00) 99.8%);
    position: relative;
}

.section4::before {
    content: "";
    background-image: url(../images/skaterPage1.jpg);
    background-size: cover;
    background-position: left;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.section4 .content {
    display: flex;
    margin-left: 10px;
    align-content: flex-start;
}
.section4 .heading {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-content: center;
    margin-top: 130px;
}
.icon-custom1,
.icon-custom2 {
    width: 48px;
    height: 48px;

}

.section4 .main-heading {
    display: flex;
    align-self: center;
    margin-left: 10px;
    text-align: center;
    color: rgba(255, 255, 255, 0.80);
    font-size: 64px;
    font-family: Inter;
    gap: 28px;
    font-weight: 400;
    text-transform: uppercase;
    word-wrap: break-word;
}

.customize-section {
    display: flex;
    align-self: center;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 24px;
    border-radius: 16px;

}

.seamless-section {

    display: flex;
    height: 50%;

    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding: 40px 24px;

    border-radius: 16px;

}

.customize {
    color: rgba(255, 255, 255, 0.90);
    font-size: 20px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;

}

.membership {
    flex: 1 1 0;
    color: white;
    font-size: 14px;
    font-family: Inter;
    font-weight: 200;
    word-wrap: break-word;
    text-align: center;
    line-height: normal;
    width: 100%;
}

.seemless {
    color: rgba(255, 255, 255, 0.90);
    font-style: normal;
    font-size: 20px;
    line-height: normal;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
}

.effortless {
    color: white;
    text-align: center;
    font-size: 14px;
    line-height: normal;
    font-family: Inter;
    font-weight: 200;
    word-wrap: break-word;
    width: 100%;

}


/* Section-5 Styles */
.section5 {
    /* width: 1440px; */
    /* height: 960px; */
    /* flex-shrink: 0 ; */
    width: 100%;
    height: 100%;

    /* display: grid; */

    grid-template-columns: repeat(2, 1fr);
    background-image: url(../images/basketballPage1.jpg);
    background: linear-gradient(270deg, rgba(20, 36, 54, 0.8) 10.78%, rgba(0, 9, 23, 0.00) 99.8%);

    background-size: cover;
    background-position: center;
    color: #fff;
    align-items: center;
    min-height: 130vh;
    /* opacity: 0.7; */
    position: relative;
}
.section5::before {
    content: "";
    background-image: url(../images/basketballPage1.jpg);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.section5 .content5 {
    /* margin-left: 80px; */
    display: flex;
    justify-content: flex-end;

}

.section5 .heading5 {
    display: flex;
    flex-direction: column;
    width: 50%;

    float: right;
    justify-content: flex-end;
    align-content: center;
    /* margin: 75px 25px; */

    /* align-content: center; */
    margin-top: 130px;
}

.icon-custom1,
.icon-custom2 {
    width: 48px;
    height: 48px;
}

.section5 .main-heading5 {

    text-align: center;
    align-self: flex-end;
    margin-right: 130px;
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.80);
    font-size: 64px;
    font-family: Inter;
    gap: 28px;
    font-weight: 400;
    text-transform: uppercase;
    word-wrap: break-word;
}

.customize-section5 {
    display: flex;
    align-self: flex-end;
    margin-right: 160px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 24px;
    border-radius: 16px;
}

.seamless-section5 {

    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    padding: 40px 24px;
    margin-right: 160px;
    border-radius: 16px;


}

.customize5 {
    color: rgba(255, 255, 255, 0.90);
    font-size: 20px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;

}

.membership5 {
    flex: 1 1 0;
    color: white;
    font-size: 14px;
    font-family: Inter;
    font-weight: 200;
    word-wrap: break-word;
    text-align: center;
    line-height: normal;
    width: 100%;
}

.seemless5 {
    color: rgba(255, 255, 255, 0.90);
    font-style: normal;
    font-size: 20px;
    line-height: normal;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
}

.effortless5 {
    color: white;
    text-align: center;
    font-size: 14px;
    line-height: normal;
    font-family: Inter;
    font-weight: 200;
    word-wrap: break-word;
    width: 100%;

}


/* Section 6 */

.section6 {
    /* width:100%;
    height: 100%; */
    /* width: 1440px; */
    /* height: 960px; */
    flex-shrink: 0;

    grid-template-columns: repeat(2, 1fr);
    background-image: url(../images/lawnTennisPage1.jpg);
    background-size: cover;
    background-position: center;
    color: #fff;
    align-items: center;
    min-height: 130vh;
    /* opacity: 0.97; */
    background: linear-gradient(90deg, rgba(41, 41, 41, 0.6) 30.78%, rgba(0, 9, 23, 0.00) 99.8%);
    position: relative;
}

.section6::before {
    content: "";
    background: url(../images/lawnTennisPage1.jpg);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.section6 .content {
    display: flex;
    margin-left: 10px;
    align-content: flex-start;
}

.section6 .heading {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-content: center;
    margin-top: 130px;
}
.icon-custom1,
.icon-custom2 {
    width: 48px;
    height: 48px;

}

.section6 .main-heading {
    display: flex;
    align-self: center;
    margin-left: 10px;
    text-align: center;
    color: rgba(255, 255, 255, 0.80);
    font-size: 64px;
    font-family: Inter;
    gap: 28px;
    font-weight: 400;
    text-transform: uppercase;
    word-wrap: break-word;
}

.customize-section {
    display: flex;
    align-self: center;
    height: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 24px;
    border-radius: 16px;

}

.seamless-section {

    display: flex;
    height: 50%;

    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding: 40px 24px;

    border-radius: 16px;

}

.customize {
    color: rgba(255, 255, 255, 0.90);
    font-size: 20px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;

}

.membership {
    flex: 1 1 0;
    color: white;
    font-size: 14px;
    font-family: Inter;
    font-weight: 200;
    word-wrap: break-word;
    text-align: center;
    line-height: normal;
    width: 100%;
}

.seemless {
    color: rgba(255, 255, 255, 0.90);
    font-style: normal;
    font-size: 20px;
    line-height: normal;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
}

.effortless {
    color: white;
    text-align: center;
    font-size: 14px;
    line-height: normal;
    font-family: Inter;
    font-weight: 200;
    word-wrap: break-word;
    width: 100%;

}


/* Section-7 Styles */
.section7 {
    /* width: 1440px;
    height: 960px; */
    /* flex-shrink: 0 ; */

    /* display: grid; */

    grid-template-columns: repeat(2, 1fr);
    background-image: url(../images/cricketPage1.jpg);
    background-size: cover;
    background-position: center;
    color: #fff;
    align-items: center;
    min-height: 100vh;
    opacity: 1;
    background: linear-gradient(270deg, rgba(51, 69, 90, 0.8) 10.78%, rgba(0, 9, 23, 0.00) 99.8%);
    position: relative;
}
.section7::before {
    content: "";
    background-image: url(../images/cricketPage1.jpg);
    /* background-size: cover; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.section7 .content7 {
    /* margin-left: 80px; */
    display: flex;
    justify-content: flex-end;

}

.section7 .heading7 {
    display: flex;
    flex-direction: column;
    width: 50%;

    float: right;
    justify-content: flex-end;
    align-content: center;
    /* margin: 75px 25px; */

    /* align-content: center; */
    margin-top: 130px;
}

.icon-custom1,
.icon-custom2 {
    width: 48px;
    height: 48px;
}



.section7 .main-heading7 {

    text-align: center;
    align-self: flex-end;
    margin-right: 130px;
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.80);
    font-size: 64px;
    font-family: Inter;
    gap: 28px;
    font-weight: 400;
    text-transform: uppercase;
    word-wrap: break-word;
}

.customize-section7 {
    display: flex;
    align-self: flex-end;
    margin-right: 220px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 24px;
    border-radius: 16px;

}

.seamless-section7 {

    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    padding: 40px 24px;
    margin-right: 220px;
    border-radius: 16px;

}

.customize7 {
    color: rgba(255, 255, 255, 0.90);
    font-size: 20px;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;

}

.membership7 {
    flex: 1 1 0;
    color: white;
    font-size: 14px;
    font-family: Inter;
    font-weight: 200;
    word-wrap: break-word;
    text-align: center;
    line-height: normal;
    width: 100%;
}

.seemless7 {
    color: rgba(255, 255, 255, 0.90);
    font-style: normal;
    font-size: 20px;
    line-height: normal;
    font-family: Inter;
    font-weight: 400;
    word-wrap: break-word;
}

.effortless7 {
    color: white;
    text-align: center;
    font-size: 14px;
    line-height: normal;
    font-family: Inter;
    font-weight: 200;
    word-wrap: break-word;
    width: 100%;

}


/*Section 8 style*/
/* Styles for Section 8 */


/* Section-7 Styles */
.section8 {
    /* width: 1440px; */
    /* height: 960px;  */
    flex-shrink: 0;
    /* display: grid; */

    background-color: #081F40;

    grid-template-columns: 1fr 2fr;
    background-size: cover;
    align-items: center;
    min-height: 100vh;
    gap: 20px;
    flex-direction: column;

    color: var(--White, #FFF);

    /* Background Image */
    position: relative;
}
.section7::before {
    content: "";
    /* background-size: cover; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.section8 .content8 {
    /* margin-left: 80px; */
    display: flex;
    flex-direction: row;

}


/* Heading text */
.section8 .heading-text8 {
    color: var(--White, #FFF);
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 48px;
    /* 133.333% */
}

/* Description text */
.section8 .description8 {
    width: 409px;
    color: var(--White-90, rgba(255, 255, 255, 0.90));
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.superdiv8 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
    gap: 40px;
    margin-left: 130px;
    margin-top: 328px;
    margin-bottom: 327px;

}


.section8 .second-part8 {

    flex-shrink: 0;

    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}

.section8 .second-part8 img {
    max-width: 60vw;
    /* block-size: 650px; */
    margin-right: 60px;
    /* border-radius: 8px; */
}




/* Button styles */

.section8 .button-s8 {
    display: flex;
    align-items: flex-start;
    gap: 28px;
    margin-top: 5px;

}

.section8 .book-demo-s8 {
    background: var(--Secondary-brand-color, #62ECBC);
    color: var(--Primary-1, #081F40);
    display: flex;
    padding: 16px 28px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* style */
    border-radius: 8px;
    backdrop-filter: blur(2px);
    /* typography */
    color: var(--Primary-1, #081F40);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.section8 .contact-us-s8 {

    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    background: none;
    color: #FFF;

    display: flex;
    padding: 12px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 8px;
    border: 1px solid var(--White, #FFF);
    backdrop-filter: blur(2px);
}
