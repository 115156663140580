/* font family */

.headingFont {
  font-family: 'Montserrat', sans-serif;
}

.bodyFont {
  font-family: 'Open Sans', sans-serif;
}

.d-flex {
  display:flex
}

.align-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center
}
.justify-content-between {
  justify-content:space-between
}

.justify-content-around {
  justify-content:space-around
}

.font12 {
  font-size: 12px;
}
.font13 {
  font-size: 13px;
}
.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font20 {
  font-size: 20px;
}

.font30 {
  font-size: 30px;
}

.m-1{
  margin: 1rem;
}

.m-2{
  margin: 2rem;
}

.mr-1 {
  margin-right: 0.5rem;
}
.mr-2 {
  margin-right: 2rem;
}

.ml-1 {
  margin-left: 0.5rem;
}
.ml-2 {
  margin-left: 1rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: 0.5rem;
}
.mt-2 {
  margin-top: 1rem;
}

.p-1{
  padding: 1rem;
}
.width{
  width:90%;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.p-11{
  padding: 0.5rem 2rem;
}
.py-3 {
  padding: 1rem ;
} 
.px-4 {
  padding: 2rem;
}

.py-2 {padding: 2rem;}
.px-3 {padding: 3rem;}

.p-20 {
  padding:20px;
}

.primary {
  color: #88ffb8;
  background: black
}

/* font weight */

.font-weight-bold {
  font-weight: bold;
}

/* border radius */

.border_radius5 {
  border-radius: 5px;
}

.border_radius30 {
  border-radius: 30px;
}

.border {
  border: none;
}

.bgImg1 {
  background: url("./images/sport1.png")
}

.object-fit-contain {
  object-fit: contain;
}

/* positions */

.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}

.topContent {
  position: absolute;
    top: 8px;
    left: 40px;
}

.leftContent {
  position: absolute;
  top: 12px;
  right: 40px;
  color: #88ffb8;
}
.bottomLeft {
  position: absolute;
  top: 200px;
  left: 100px
}

.align-content-right {
  top: 250px;
  left: 100px;
}

.align-content-left {
  top: 200px;
  right: 150px;
}
.font40 {
  font-size: 40px;
}
.font50 {
  font-size: 50px;
}

.font75 {
  font-size: 75px;
}

.font60 {
  font-size: 60px;
}

.font-weight-light {
  font-weight: 200;
}

.bg-image {
  /* background: url("./images/sport1.png") */
}

.footer {
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
}

.white {
  color: #ffffff
}
.black {
  color: #000000
}

ul li {
  list-style: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: bold;
  padding: 3px 0px
}

.desktop-bg {
  background-color: #efefef;
}

.mobile-bg{
  background-color: #00002c;
}

.mt-20 {
  margin-top: -20rem;
}
.d-block {display:block}

.mobile-bg-image {
  background-image: url("./images/mobile2.png")
}

.align-mobile-content {
  top: 70px;
}

.align-mobile-bottmContent {
  top: 95px;
  left: 25px
}

.header {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 50px;
}

@media screen and (max-width:  475px) {
  .header {
    height: 70px;
  }
}

.line-height{
  line-height: 1;
}

.about, .contactUs {
  text-decoration: none;
  color: #88ffb8;
}

a :active {
  color: #88ffb8;
}

a :hover {
  color: #88ffb8;
}